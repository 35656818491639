import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['companySelect', 'companyAttachments', 'companyAttachmentsContainer', 'container', 'companySelectLabel', 'fileField', 'fileInput', 'tagsContainer', 'hint', 'census'];

  connect() {
    let type = $('input[name=company_type]:checked').val();
    if (type) {
      this.typeChanged({
        params: {
          type: type,
        },
      });
    }
  }

  typeChanged(e) {
    $(this.fileInputTarget).val('');
    $(this.containerTarget).removeClass('d-none');
    if (e.params.type === 'multiple') {
      $(this.companySelectTarget).attr('multiple', true);
      $(this.companySelectLabelTarget).html('Select Companies');
      $(this.fileFieldTarget).addClass('d-none');
      $(this.hintTarget).show();
      $(this.fileInputTarget).removeAttr('required');
    } else {
      $(this.companySelectTarget).removeAttr('multiple');
      $(this.companySelectLabelTarget).html('Select Company');
      $(this.fileFieldTarget).removeClass('d-none');
      $(this.hintTarget).hide();
    }
    this.initCompaniesSelect($(this.companySelectTarget).data(`companies-${e.params.type}-url`));
    this.updateTags();
  }

  initCompaniesSelect(url) {
    let companies_select = $(this.companySelectTarget);
    let that = this;
    let companies_attachment;
    let companies_attachment_container;
    let select_options = {
      ajax: {
        url: url,
        dataType: 'json',
        delay: 250,
        data: function (params) {
          return {
            q: params.term || '',
            page: params.page || 1,
          };
        },
      },
      templateSelection: function (container) {
        $(container.element).attr('data-is-paychex', container.data?.is_paychex || container.element.dataset?.isPaychex);
        return container.text;
      },
    };

    companies_select.val('');
    if (this.hasCompanyAttachmentsTarget) {
      companies_attachment = $(this.companyAttachmentsTarget);
      companies_attachment_container = $(this.companyAttachmentsContainerTarget);
      new Select2Cascade(companies_select, companies_attachment, '/companies/attachments_json?ids=:parentId:', select_options);
    } else {
      if (companies_select.data('select2')) {
        companies_select.select2('destroy');
      }
      companies_select.select2(select_options);
    }

    companies_select.on('change', function () {
      that.updateTags();
      if (companies_attachment !== undefined) {
        let selectionsCount = that.selectionCount();
        if (selectionsCount > 1 || selectionsCount === 0) {
          companies_attachment.val('');
          companies_attachment_container.hide();
        } else {
          companies_attachment_container.show();
        }
      }

      if ($(this).find(':selected').length === 1) {
        if ($(this).find(':selected').data().isPaychex) {
          $(that.fileInputTarget).removeAttr('required');
          $(that.censusTarget).removeClass('d-none');
        } else {
          $(that.fileInputTarget).attr('required', 'required');
          $(that.censusTarget).addClass('d-none');
        }
      }
    });
  }

  selectionCount() {
    if ($(this.companySelectTarget).val() == null) {
      return 0;
    }

    if ($(this.companySelectTarget).val().constructor.name === 'Array') {
      return $(this.companySelectTarget).val().length;
    }

    return 1;
  }

  updateTags() {
    let tags_container = $(this.tagsContainerTarget);

    if (this.selectionCount() >= 1) {
      tags_container.hide();
    } else {
      tags_container.show();
    }
  }
}
