import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['tabs', 'form', 'prevBtn', 'nextBtn', 'step', 'stepBox', 'heading'];
  currentPage = 0;

  connect() {
    let count = this.tabsTargets.length;
    for (let i = 0; i < count; i++) {
      this.stepBoxTarget.insertAdjacentHTML('beforeend', '<span class="step" data-task-form-target="step"></span>');
    }

    if (document.querySelector('.form-group-invalid')) {
      this.currentPage = Array.from(document.querySelectorAll('.task-tab')).indexOf(document.querySelector('.form-group-invalid').closest('.task-tab'));
    }
    this.showTab(this.currentPage);
  }

  nextPrev(event) {
    let n = parseInt(event.params.n);
    let x = this.tabsTargets;
    if (n === 1 && !this.validateForm()) return false;
    let y = this.currentPage;
    this.currentPage += n;
    if (this.currentPage >= x.length) {
      this.formTarget.submit();
      return false;
    } else {
      x[y].classList.add('d-none');
    }
    this.showTab(this.currentPage);
  }

  validateForm() {
    let valid = true;
    let elements = this.tabsTargets[this.currentPage].querySelectorAll('input, select');
    elements.forEach(element => {
      if (!element.checkValidity()) {
        element.reportValidity();
        valid = false;
      }
    });
    return valid;
  }

  hideTabs() {
    this.tabsTargets.forEach(tab => {
      tab.classList.add('d-none');
    });
  }

  showTab(n) {
    this.hideTabs();
    // This function will display the specified tab of the form...
    this.tabsTargets[n].classList.remove('d-none');
    //... and fix the Previous/Next buttons:
    if (n === 0) {
      this.prevBtnTarget.classList.add('d-none');
    } else {
      this.prevBtnTarget.classList.remove('d-none');
    }
    if (n === (this.tabsTargets.length - 1)) {
      this.nextBtnTarget.textContent = 'Submit';
    } else {
      this.nextBtnTarget.textContent = 'Next';
    }
    //... and run a function that will display the correct step indicator:
    this.fixStepIndicator(n);
  }

  fixStepIndicator(n) {
    this.stepTargets.forEach((step, index) => {
      step.classList.toggle('finish', index <= n);
    });
  }

  nameChanged(event) {
    if (event.target.value.length > 0) {
      this.headingTarget.textContent = event.target.value;
    } else {
      this.headingTarget.textContent = 'New Task';
    }
  }
}
