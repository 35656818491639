// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { Application } from "@hotwired/stimulus"

import CompanySelectController from "./controllers/company_select_controller"
import TaskFormController from "./controllers/task_form_controller"

window.Stimulus = Application.start()
Stimulus.register("company-select", CompanySelectController)
Stimulus.register("task-form", TaskFormController)
